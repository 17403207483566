import { Component, OnInit } from '@angular/core';
import { ItemType } from '@app/models/item';
import { AddItem } from '@app/store/actions/cart.action';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CartState } from '../store/state/cart.state';


@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
    productId = '';
    driveId = '';
    channelId = '';
    newsletterId = '';
    invitationId = '';
    product_videoId = '';
    product_radioId = '';
    product_vpsid = '';
    siteBuilderId = '';
    mailServiceId = '';
    ksuiteId = '';
    supportPremiumId = '';
    ready = false;
    @Select(CartState.links)
    links$: Observable<any>;

    constructor(private store: Store) {
        this.links$.subscribe(links => {
            if (
                links && environment.env === 'prod') {
                if (Object.keys(window['CONST_USER']).length > 0) {
                    window.location.assign(links?.manager || 'https://manager.nativeops.ch');
                } else {
                    window.location.assign(links.hosting);
                }
            } else {
                this.ready = true;
            }
        });
    }

    ngOnInit() { }

    onEnter(href: string) {
        window.location.href = href;
    }
    addAnItem(type: ItemType) {
        this.store.dispatch(new AddItem(type));
    }
}
